import { httpsCallable } from 'firebase/functions'

import { functions } from '@/shared/firebase/firebase.ts'
import {
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse,
} from '@monorepo/functions/src/types/NewFeedbackQuestionAnswered'
import {
    SubscribeToMailerLiteRequest,
    SubscribeToMailerLiteResponse,
} from '@monorepo/functions/src/types/SubscribeToMailerLite'

const newFeedbackQuestionAnsweredCloudFn = httpsCallable<
    NewFeedbackQuestionAnsweredRequest,
    NewFeedbackQuestionAnsweredResponse
>(functions, 'newFeedbackQuestionAnswered')
export const newFeedbackQuestionAnswered = (req: NewFeedbackQuestionAnsweredRequest) => {
    // we do not await on purpose, as it is an async process
    newFeedbackQuestionAnsweredCloudFn(req)
}

export const subscribeToMailerLite = httpsCallable<SubscribeToMailerLiteRequest, SubscribeToMailerLiteResponse>(
    functions,
    'subscribeToMailerLite',
)
