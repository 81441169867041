import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { signOut } from 'firebase/auth'
import { Info } from 'lucide-react'

import { categories } from '@/pages/dashboard/GenerateSampleMessages.tsx'
import { InsightsCard } from '@/pages/dashboard/components/InsightsCard'
import { InsightsPieChart } from '@/pages/dashboard/components/InsightsPieChart'
import { LikertInfoModal, getColorForValue, getShortDescription } from '@/pages/form/components/LikertInfoModal.tsx'
import { useAuth } from '@/shared/auth.tsx'
import { auth } from '@/shared/firebase/firebase'
import { TrackingService } from '@/shared/lib/tracking'
import { Button } from '@/shared/ui/button'
import { useEventId } from '@/shared/useEventId.ts'
import {
    Category,
    Department,
    LikertFeedback,
    MultiSelectFeedback,
    OptionsSliderFeedback,
    RadioFeedback,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

import { CommentsSidebar } from './CommentsSidebar'
import { DepartmentsSection } from './DepartmentsSection'
import { FilterSection } from './components/FilterSection'
import { useFeedback } from './context/FeedbackContext'
import { SummarySection } from './features/summary/SummarySection'
import { categoryIcons, getCategoryColor } from './lib'

const getYesNoChartData = (question: string, feedbacks: YesNoFeedback[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)

    const yesCount = answers.filter(a => a.value).length
    const noCount = answers.filter(a => !a.value).length
    return [
        { id: 0, value: yesCount, label: 'Sí', color: '#22c55e' },
        { id: 1, value: noCount, label: 'No', color: '#EF4444' },
    ]
}
const CHART_COLORS = [
    '#e84750',
    '#06b6d4',
    '#ec4899',
    '#4ade80',
    '#eab308',
    '#ff5733',
    '#33ff57',
    '#3357ff',
    '#ff33a1',
    '#a133ff',
]

const getSingleSelectChartData = (question: string, feedbacks: (RadioFeedback | OptionsSliderFeedback)[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0].question.options
    return possibleAnswers.map((answer, index) => ({
        id: index,
        value: answers.filter(a => a.value === answer.value).length,
        label: answer.label,
        color: CHART_COLORS[index] ?? 'purple',
    }))
}

const getMultiSelectChartData = (question: string, feedbacks: MultiSelectFeedback[]) => {
    const answers = feedbacks.filter(q => q.question.title === question).map(r => r.answer)
    const possibleAnswers = feedbacks[0].question.options

    return possibleAnswers.map((answer, index) => ({
        id: index,
        value: answers.filter(a => a.value.includes(answer.value)).length,
        label: answer.label,
        color: CHART_COLORS[index] ?? 'purple',
    }))
}

export interface Props {
    isDemo?: boolean
}

export const Dashboard = ({ isDemo }: Props) => {
    const { isLoggedIn } = useAuth()
    const navigate = useNavigate()

    const [selectedCategory, setSelectedCategory] = useState<Category>(categories[0])
    const [selectedDepartments, setSelectedDepartments] = useState<Department[]>([])
    const [selectedCategories, setSelectedCategories] = useState<Category[]>([])
    const eventId = useEventId()

    const {
        textFeedbacks,
        likertFeedbacks,
        yesNoFeedbacks,
        optionsSliderFeedback,
        multiSelectFeedback,
        radioFeedback,
        setActiveFilters,
        availableQuestions,
    } = useFeedback()

    const [isLikertModalOpen, setIsLikertModalOpen] = useState(false)

    const toggleLikertModal = () => {
        setIsLikertModalOpen(prev => !prev)
    }

    useEffect(() => {
        TrackingService.trackPageView({
            eventId,
            page: 'dashboard',
        })
    }, [eventId])

    const getAverageScore = (feedbacks: LikertFeedback[], question: string) => {
        const filteredFeedbacks = feedbacks.filter(f => f.question.title === question)
        const sum = filteredFeedbacks.reduce((a, b) => a + b.answer.value, 0)
        const avg = sum / filteredFeedbacks.length

        return Math.round(avg * 100) / 100
    }

    const handleCategoryClick = (category: Category) => {
        setSelectedCategory(category)
        setSelectedCategories([category])
        setSelectedDepartments([])
    }

    const logout = async () => {
        await signOut(auth)
        navigate('../login')
    }

    const handleFiltersChange = (filters: FilterItem[]) => {
        console.log('Filters changed:', filters)
        setActiveFilters(filters)
    }

    return (
        <div className='flex h-screen overflow-hidden bg-gradient-to-br from-gray-900 to-black text-white'>
            {/* Left Panel */}
            <div className='no-scrollbar mb-4 w-4/6 overflow-x-auto border-r border-gray-700 p-6'>
                <div className='mb-5 flex items-center justify-between'>
                    <img className='w-80' src='/boothbits-logo.svg' alt='BoothBits logo' />
                    {isLoggedIn && (
                        <div>
                            {/* TODO is the SupportUsDialog feature ready? */}
                            {/* <SupportUsDialog /> */}
                            <Button variant='ghost' onClick={logout}>
                                Cerrar sesión
                            </Button>
                        </div>
                    )}
                </div>

                <div className='my-4'>
                    <FilterSection onFiltersChange={handleFiltersChange} availableQuestions={availableQuestions} />
                </div>

                <div className='flex w-full flex-row gap-3 overflow-y-auto'>
                    {categories.map(category => (
                        <button
                            key={category}
                            className={`flex w-full flex-row items-center gap-3 rounded-lg p-3 text-left text-xl shadow-lg transition-all duration-300 ${
                                selectedCategory === category
                                    ? `bg-gradient-to-r ${getCategoryColor(category)} text-white`
                                    : 'bg-gray-800 hover:bg-gray-700'
                            }`}
                            onClick={() => handleCategoryClick(category)}
                        >
                            <span>{categoryIcons[category]}</span>
                            {category}
                        </button>
                    ))}
                </div>
                <DepartmentsSection
                    selectedCategory={selectedCategory}
                    setSelectedCategories={setSelectedCategories}
                    setSelectedDepartments={setSelectedDepartments}
                    textFeedbacks={textFeedbacks}
                />
                <SummarySection selectedCategory={selectedCategory} isDemo={isDemo} />

                {/* Insights Container */}
                <div className='mt-8'>
                    <div className='flex items-center justify-between'>
                        <h3 className='mb-4 bg-gradient-to-r from-blue-400 to-cyan-600 bg-clip-text text-xl font-semibold text-transparent'>
                            Insights
                        </h3>
                        <Button
                            onClick={toggleLikertModal}
                            className='mb-4 bg-gray-700 text-white hover:bg-gray-600'
                            aria-label='Información sobre la Escala Likert'
                        >
                            <Info className='mr-2 h-4 w-4' />
                            Escala Likert
                        </Button>
                    </div>
                    <div className='grid grid-cols-2 gap-3'>
                        {/* Likert Scale Insights */}
                        {[...new Set(likertFeedbacks.map(f => f.question.title))].map(questionTitle => {
                            const feedbacks = likertFeedbacks.filter(
                                feedback => feedback.question.title === questionTitle,
                            )
                            const avg = getAverageScore(feedbacks, questionTitle)
                            const category = avg >= 4 ? 'Fortalezas' : 'Problemas'
                            if (category === selectedCategory) {
                                return (
                                    <InsightsCard
                                        key={questionTitle}
                                        numberOfFeedbacks={feedbacks.length}
                                        questionTitle={questionTitle}
                                    >
                                        <div className='flex h-[200px] flex-col items-center justify-center'>
                                            <span
                                                className={`text-6xl font-bold text-${getColorForValue(avg)} tooltip`}
                                            >
                                                {avg.toFixed(1)}
                                                <Button
                                                    onClick={toggleLikertModal}
                                                    className='absolute right-[-30px] top-[-15px] ml-2 flex h-8 w-8 items-center justify-center rounded-full bg-transparent p-2 hover:bg-gray-700'
                                                    aria-label='Abrir explicación de la Escala Likert'
                                                >
                                                    <Info className='h-5 w-5 text-gray-400 hover:text-white' />
                                                </Button>
                                            </span>
                                            <div className='mt-2 text-center'>
                                                <span className={`text-sm text-${getColorForValue(avg)}`}>
                                                    {getShortDescription(avg)}
                                                </span>
                                            </div>
                                        </div>
                                    </InsightsCard>
                                )
                            }
                            return null
                        })}

                        {/* Yes/No Insights */}
                        {[...new Set(yesNoFeedbacks.map(f => f.question.title))].map(questionTitle => {
                            const feedbacks = yesNoFeedbacks.filter(
                                feedback => feedback.question.title === questionTitle,
                            )
                            const yesPercentage =
                                (feedbacks.filter(feedback => feedback.answer.value).length / feedbacks.length) * 100
                            const category = yesPercentage >= 80 ? 'Fortalezas' : 'Problemas'
                            if (category === selectedCategory) {
                                const yesNoChartData = getYesNoChartData(questionTitle, feedbacks)
                                return (
                                    <InsightsCard
                                        key={questionTitle}
                                        questionTitle={questionTitle}
                                        numberOfFeedbacks={feedbacks.length}
                                    >
                                        <InsightsPieChart data={yesNoChartData} />
                                    </InsightsCard>
                                )
                            }
                            return null
                        })}

                        {/* Radio Insights */}
                        {[...new Set(radioFeedback.map(f => f.question.title))].map(questionTitle => {
                            const feedbacks = radioFeedback.filter(
                                feedback => feedback.question.title === questionTitle,
                            )
                            if (selectedCategory === 'Otros') {
                                const radioChartData = getSingleSelectChartData(questionTitle, feedbacks)
                                return (
                                    <InsightsCard
                                        key={questionTitle}
                                        questionTitle={questionTitle}
                                        numberOfFeedbacks={feedbacks.length}
                                    >
                                        <InsightsPieChart data={radioChartData} />
                                    </InsightsCard>
                                )
                            }
                            return null
                        })}

                        {/* Multi Select Insights */}
                        {[...new Set(multiSelectFeedback.map(f => f.question.title))].map(questionTitle => {
                            const feedbacks = multiSelectFeedback.filter(
                                feedback => feedback.question.title === questionTitle,
                            )
                            if (selectedCategory === 'Otros') {
                                const radioChartData = getMultiSelectChartData(questionTitle, feedbacks)
                                return (
                                    <InsightsCard
                                        key={questionTitle}
                                        questionTitle={questionTitle}
                                        numberOfFeedbacks={feedbacks.length}
                                    >
                                        <InsightsPieChart data={radioChartData} />
                                    </InsightsCard>
                                )
                            }
                            return null
                        })}

                        {/* Options Slider Insights */}
                        {[...new Set(optionsSliderFeedback.map(f => f.question.title))].map(questionTitle => {
                            const feedbacks = optionsSliderFeedback.filter(
                                feedback => feedback.question.title === questionTitle,
                            )
                            if (selectedCategory === 'Otros') {
                                const radioChartData = getSingleSelectChartData(questionTitle, feedbacks)
                                return (
                                    <InsightsCard
                                        key={questionTitle}
                                        questionTitle={questionTitle}
                                        numberOfFeedbacks={feedbacks.length}
                                    >
                                        <InsightsPieChart data={radioChartData} />
                                    </InsightsCard>
                                )
                            }
                            return null
                        })}
                    </div>
                </div>
            </div>
            <CommentsSidebar
                selectedCategory={selectedCategory}
                selectedCategories={selectedCategories}
                selectedDepartments={selectedDepartments}
                setSelectedCategories={setSelectedCategories}
                setSelectedDepartments={setSelectedDepartments}
                textFeedbacks={textFeedbacks}
            />
            <LikertInfoModal isOpen={isLikertModalOpen} onClose={toggleLikertModal} />
        </div>
    )
}
