import { FormEvent, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { motion } from 'framer-motion'
import { ArrowRight, Star, Timer, Users } from 'lucide-react'
import validator from 'validator'

import BimeLogo from '@/pages/form/assets/img/bime-logo-black.png'
import { PoweredBy } from '@/pages/form/components/PoweredBy.tsx'
import { subscribeToMailerLite } from '@/shared/api/clients.ts'
import { Button } from '@/shared/ui/button.tsx'
import { Input } from '@/shared/ui/input.tsx'

const GENERAL_MAIL_GROUP_ID = '136522864619685881'
const BIME_MAIL_GROUP_ID = '136377935469741205'
const BIME_ASSISTANTS_MAIL_GROUP_ID = '136522852067181820'
const BIME_PARTICIPANTS_MAIL_GROUP_ID = '136522859200644213'

const ASSISTANTS_REGEX = /-assistants/
const PARTICIPANTS_REGEX = /-participants/

const addEmailSubscriber = async (email: string, extraGroups: string[] = []) => {
    try {
        await subscribeToMailerLite({
            email,
            groups: [GENERAL_MAIL_GROUP_ID, BIME_MAIL_GROUP_ID, ...extraGroups],
        })
    } catch (error) {
        console.error(error)
    }
}

export const FormSummary: React.FC = () => {
    const [email, setEmail] = useState('')
    const [isSubmitted, setIsSubmitted] = useState(false)
    const location = useLocation()

    const submit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault()

        const assitantsEmailGroup = ASSISTANTS_REGEX.test(location.pathname) ? [BIME_ASSISTANTS_MAIL_GROUP_ID] : []
        const participantsEmailGroup = PARTICIPANTS_REGEX.test(location.pathname)
            ? [BIME_PARTICIPANTS_MAIL_GROUP_ID]
            : []

        const extraGroups = [...assitantsEmailGroup, ...participantsEmailGroup]

        addEmailSubscriber(email, extraGroups)
        setIsSubmitted(true)
    }

    const isValidEmail = validator.isEmail(email)

    return (
        <form className='flex flex-col items-center justify-center' onSubmit={submit}>
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='animate-slide-in text-center font-bime-knockout-welter text-3xl text-secondary-foreground'
            >
                ¡<span className='font-bime-knockout-hevi'>G</span>
                RA
                <span className='font-bime-knockout-fly'>CI</span>
                AS <span className='font-bime-knockout-bantam'>P</span>
                <span className='font-bime-knockout-hevi'>O</span>
                <span className='font-bime-knockout-bantam'>R</span> D
                <span className='font-bime-knockout-bantam'>AR</span> T<span className='font-bime-knockout-fly'>U</span>{' '}
                <span className='font-bime-knockout-hevi'>O</span>
                <span className='font-bime-knockout-bantam'>PI</span>
                NI
                <span className='font-bime-knockout-feather'>ÓN!</span>
            </motion.h1>
            <PoweredBy transition={{ delay: 0.4, duration: 0.5 }} />
            <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className='rounded-lg border-2 bg-white'
            >
                <div className='space-y-6 p-6'>
                    <ul className='grid gap-4'>
                        <motion.li
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 0.8, duration: 0.5 }}
                            className='flex items-center gap-3'
                        >
                            <div className='rounded-full bg-primary/10 p-2'>
                                <Timer className='h-5 w-5 text-primary' />
                            </div>
                            <p className='text-sm'>Análisis instantáneo de más de 500 feedbacks</p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1, duration: 0.5 }}
                            className='flex items-center gap-3'
                        >
                            <div className='rounded-full bg-primary/10 p-2'>
                                <Star className='h-5 w-5 text-primary' />
                            </div>
                            <p className='text-sm'>Insights potentes impulsados por IA</p>
                        </motion.li>
                        <motion.li
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ delay: 1.2, duration: 0.5 }}
                            className='flex items-center gap-3'
                        >
                            <div className='rounded-full bg-primary/10 p-2'>
                                <Users className='h-5 w-5 text-primary' />
                            </div>
                            <p className='text-sm'>Panel interactivo con toda la información resumida</p>
                        </motion.li>
                    </ul>
                    {!isSubmitted ? (
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ delay: 1.4, duration: 0.5 }}
                        >
                            <h2 className='mb-4 text-center text-lg font-semibold'>¿Lo quieres en tu evento?</h2>
                            <div className='space-y-2'>
                                <Input
                                    type='email'
                                    placeholder='Escribe tu email aquí...'
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    className='w-full'
                                />
                                <Button className='group w-full' size='lg' type='submit' disabled={!isValidEmail}>
                                    ¡LA QUIERO!
                                    <ArrowRight className='ml-2 h-4 w-4 transition group-hover:translate-x-1' />
                                </Button>
                            </div>
                        </motion.div>
                    ) : (
                        <p className='text-md'>¡Gracias! Recibirás noticias pronto...</p>
                    )}
                </div>
            </motion.div>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 1.6, duration: 0.5 }}
                className='mt-4 space-y-4 text-center'
            >
                <p className='text-sm text-foreground/80'>Únete a +1000 personas que ya confían en nosotros</p>
            </motion.div>
            <motion.img
                initial={{ opacity: 0 }}
                animate={{
                    scale: [0.5, 1, 1.2],
                    opacity: 1,
                }}
                transition={{ delay: 1.8, duration: 1 }}
                className='mt-4 h-10'
                src={BimeLogo}
                alt='Logo BIME'
            />
        </form>
    )
}
