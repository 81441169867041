import { HTMLAttributes } from 'react'
import { Outlet } from 'react-router-dom'

import { cn } from '@/shared/lib/styles/class-merge'

type Props = HTMLAttributes<HTMLDivElement>

export const Layout = ({ className, ...props }: Props) => (
    <div
        className={cn(
            'animate-fade-in relative flex h-full w-full flex-col items-center justify-center overflow-hidden bg-background px-8 py-4 text-primary',
            className,
        )}
        {...props}
    >
        <Outlet />
    </div>
)
