import { useEffect, useRef, useState } from 'react'

import { Check, ChevronDown, User } from 'lucide-react'

import { Button } from '@/shared/ui/button'

interface Option {
    value: string
    label: string
    description?: string
    avatar?: string
}

interface MultiSelectDropdownProps {
    options: Option[]
    selectedValues: string[]
    onChange: (values: string[]) => void
    placeholder: string
    trigger?: React.ReactNode
    type?: 'user' | 'default'
}

export const MultiSelectDropdown = ({
    options,
    selectedValues,
    onChange,
    placeholder,
    trigger,
    type = 'default',
}: MultiSelectDropdownProps) => {
    const [isOpen, setIsOpen] = useState(false)
    const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 })
    const triggerRef = useRef<HTMLDivElement>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target as Node) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const updateDropdownPosition = () => {
        if (triggerRef.current) {
            const rect = triggerRef.current.getBoundingClientRect()

            // Calcular el ancho necesario para el contenido más largo
            const maxContentWidth = Math.max(
                ...options.map(option => {
                    if (option.description) {
                        // Aumentamos los multiplicadores para dar más espacio
                        const circleWidth = 24 // ancho del círculo
                        const gap = 12 // espacio entre círculo y texto
                        const checkWidth = 28 // ancho del check + margen
                        const padding = 24 // padding horizontal total

                        // Calculamos el ancho aproximado del texto
                        const numberWidth = option.label.length * 12
                        const descriptionWidth = option.description.length * 8

                        return circleWidth + gap + numberWidth + descriptionWidth + checkWidth + padding
                    }
                    return option.label.length * 10
                }),
            )

            // Añadimos un margen extra para asegurar que todo quepa cómodamente
            const finalWidth = Math.max(rect.width, maxContentWidth + 40, 250)

            setDropdownPosition({
                top: rect.bottom + 4,
                left: rect.left,
                width: finalWidth,
            })
        }
    }

    const handleToggleDropdown = () => {
        if (!isOpen) {
            updateDropdownPosition()
        }
        setIsOpen(!isOpen)
    }

    const toggleOption = (value: string) => {
        const newValues = selectedValues.includes(value)
            ? selectedValues.filter(v => v !== value)
            : [...selectedValues, value]
        onChange(newValues)
    }

    const getSelectedLabels = () => {
        return selectedValues.map(value => options.find(opt => opt.value === value)).filter(Boolean) as Option[]
    }

    const getDisplayLabel = (option: Option) => {
        if (!option.description) return option.label
        return `${option.label} - ${option.description}`
    }

    return (
        <div className='relative'>
            <div ref={triggerRef} onClick={handleToggleDropdown}>
                {trigger || (
                    <Button
                        variant='outline'
                        className={`flex h-8 w-full items-center justify-between gap-2 rounded-md border border-gray-700 bg-gray-900 px-2 text-sm ${isOpen ? 'border-blue-500' : 'hover:border-gray-600'} ${selectedValues.length > 0 ? 'text-white' : 'text-gray-400'}`}
                    >
                        <div className='flex flex-1 items-center gap-1 overflow-hidden'>
                            {type === 'user' && <User className='h-4 w-4 text-blue-500' />}
                            {selectedValues.length === 0 ? (
                                <span className='text-gray-400'>{placeholder}</span>
                            ) : (
                                <div className='flex items-center gap-1'>
                                    {getSelectedLabels().map((option, index) => (
                                        <div key={option.value} className='flex items-center gap-1'>
                                            {index > 0 && ', '}
                                            <span className='max-w-[150px] truncate'>{getDisplayLabel(option)}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <ChevronDown
                            className={`h-4 w-4 shrink-0 transition-transform ${isOpen ? 'rotate-180' : ''}`}
                        />
                    </Button>
                )}
            </div>

            {isOpen && (
                <div
                    ref={dropdownRef}
                    style={{
                        position: 'fixed',
                        top: `${dropdownPosition.top}px`,
                        left: `${dropdownPosition.left}px`,
                        width: `${dropdownPosition.width}px`,
                    }}
                    className='z-[70] overflow-hidden rounded-md border border-gray-700 bg-gray-900 shadow-lg'
                >
                    <div className='max-h-[300px] overflow-auto py-1'>
                        {options.map(option => (
                            <button
                                key={option.value}
                                className='flex w-full items-center justify-between px-3 py-1.5 text-sm text-gray-300 hover:bg-gray-800'
                                onClick={() => toggleOption(option.value)}
                            >
                                {option.description ? (
                                    <div className='flex flex-1 items-center gap-3'>
                                        <div className='flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-gray-700 text-white'>
                                            {option.label}
                                        </div>
                                        <span className='whitespace-nowrap'>{option.description}</span>
                                    </div>
                                ) : (
                                    option.label
                                )}
                                {selectedValues.includes(option.value) && (
                                    <Check className='ml-3 h-4 w-4 shrink-0 text-blue-500' />
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    )
}
