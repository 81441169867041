import { motion } from 'framer-motion'

const FormSummary: React.FC = () => {
    return (
        <div className='flex flex-col items-center justify-center'>
            <h1 className='animate-slide-in mb-8 text-center text-3xl font-extrabold tracking-wide'>
                Gracias por tu Feedback!
            </h1>

            <motion.div
                animate={{
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, -5, 0],
                }}
                transition={{
                    duration: 2,
                    repeat: Infinity,
                    repeatType: 'reverse',
                }}
                className='mb-8 rounded-full bg-blue-600 p-5 shadow-lg'
            >
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth={2}
                    stroke='currentColor'
                    className='h-14 w-14 text-white'
                    aria-hidden='true'
                >
                    <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z'
                    />
                </svg>
            </motion.div>

            <p className='animate-fade-in-slow mb-6 max-w-lg text-center text-lg leading-relaxed'>
                Estás haciendo un Matadero mejor!
            </p>

            <div className='mb-8 w-full max-w-sm rounded-lg bg-blue-900 bg-opacity-30 p-5 text-center shadow-lg'>
                <h2 className='mb-3 text-xl font-semibold'>Porfa</h2>
                <ul className='space-y-2 text-center text-lg'>
                    <li>No te mates dentro :O</li>
                </ul>
            </div>
        </div>
    )
}

export default FormSummary
