import { useDocument } from '@/shared/firebase/hooks/useDocument.tsx'
import { useEventId } from '@/shared/useEventId.ts'
import { Questions } from '@monorepo/functions/src/types/event'

export const useQuestions = () => {
    const eventId = useEventId()
    const { document: questions } = useDocument<Questions>({
        collectionName: `events/${eventId}/questions`,
        id: 'default',
    })
    return questions?.questions
}
