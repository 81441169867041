import { HTMLAttributes } from 'react'

import { cn } from '@/shared/lib/styles/class-merge'

interface Props extends HTMLAttributes<HTMLDivElement> {
    crossXClassName?: string
    crossYClassName?: string
}

export const Cross = ({ crossXClassName, crossYClassName, ...props }: Props) => (
    <div {...props}>
        <div
            className={cn(
                'absolute left-1/2 top-1/2 h-5 w-20 -translate-x-1/2 -translate-y-1/2 bg-accent-foreground',
                crossXClassName,
            )}
        ></div>
        <div
            className={cn(
                'absolute left-1/2 top-1/2 h-20 w-5 -translate-x-1/2 -translate-y-1/2 bg-accent-foreground',
                crossYClassName,
            )}
        ></div>
    </div>
)
