/* eslint-disable react-refresh/only-export-components */
import { useEffect, useRef } from 'react'

import { X } from 'lucide-react'

import { Button } from '@/shared/ui/button.tsx'

type LikertInfoModalProps = {
    isOpen: boolean
    onClose: () => void
}

type DescriptionThreshold = {
    threshold: number
    desc: string
}

type ColorThreshold = {
    threshold: number
    color: string
}

const descriptions: string[] = [
    'Muy en desacuerdo / Muy insatisfecho',
    'En desacuerdo / Insatisfecho',
    'Neutral',
    'De acuerdo / Satisfecho',
    'Muy de acuerdo / Muy satisfecho',
]

const shortDescriptions: DescriptionThreshold[] = [
    { threshold: 1.99, desc: 'Muy insatisfecho' },
    { threshold: 2.99, desc: 'Insatisfecho' },
    { threshold: 3.99, desc: 'Neutral' },
    { threshold: 4.5, desc: 'Satisfecho' },
    { threshold: 5.01, desc: 'Muy satisfecho' },
]

const colors: ColorThreshold[] = [
    { threshold: 1.99, color: 'red-500' },
    { threshold: 2.99, color: 'orange-500' },
    { threshold: 3.99, color: 'yellow-500' },
    { threshold: 4.5, color: 'lime-500' },
    { threshold: 5.01, color: 'green-500' },
]

export const getLongDescription = (value: number): string => {
    const roundedValue = Math.ceil(value)
    return descriptions[Math.min(roundedValue, 5) - 1] || 'N/A'
}

export const getShortDescription = (value: number): string => {
    const roundedValue = Math.round(value * 10) / 10
    return shortDescriptions.find(({ threshold }) => roundedValue <= threshold)?.desc || 'Muy satisfecho'
}

export const getColorForValue = (value: number): string => {
    const roundedValue = Math.round(value * 10) / 10
    return colors.find(({ threshold }) => roundedValue <= threshold)?.color || 'green-500'
}

export const LikertInfoModal = ({ isOpen, onClose }: LikertInfoModalProps) => {
    const modalRef = useRef<HTMLDivElement>(null)
    const overlayRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const handleEscape = (event: KeyboardEvent) => {
            if (event.key === 'Escape') onClose()
        }

        const handleClickOutside = (event: MouseEvent) => {
            if (overlayRef.current && event.target === overlayRef.current) {
                onClose()
            }
        }

        if (isOpen) {
            document.addEventListener('keydown', handleEscape)
            document.addEventListener('mousedown', handleClickOutside)
            modalRef.current?.focus()
        }

        return () => {
            document.removeEventListener('keydown', handleEscape)
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen, onClose])

    if (!isOpen) return null

    return (
        <div
            ref={overlayRef}
            className='fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30 backdrop-blur-sm'
        >
            <div
                ref={modalRef}
                className='mx-4 w-full max-w-md overflow-hidden rounded-2xl bg-gradient-to-br from-gray-900 to-black shadow-2xl'
                tabIndex={-1}
                aria-labelledby='modal-title'
                role='dialog'
                aria-modal='true'
            >
                <div className='p-6'>
                    <div className='mb-6 flex items-center justify-between'>
                        <h2 id='modal-title' className='text-2xl font-semibold text-blue-400'>
                            Escala Likert
                        </h2>
                        <Button
                            onClick={onClose}
                            className='bg-gray-800 text-white hover:bg-gray-700'
                            aria-label='Cerrar'
                        >
                            <X className='h-5 w-5' />
                        </Button>
                    </div>
                    <p className='mb-6 text-gray-300'>
                        La escala Likert es una herramienta de medición que permite evaluar opiniones y actitudes en una
                        escala del 1 al 5:
                    </p>
                    <div className='mb-6 flex justify-between'>
                        {[1, 2, 3, 4, 5].map(value => (
                            <div key={value} className='flex w-1/5 flex-col items-center'>
                                <div
                                    className={`mb-2 flex h-12 w-12 items-center justify-center rounded-full text-lg font-semibold bg-${getColorForValue(value)}`}
                                >
                                    {value}
                                </div>
                                {/* <span className="text-xs text-gray-400 text-center whitespace-nowrap">{getShortDescription(value)}</span> */}
                            </div>
                        ))}
                    </div>

                    <div className='space-y-2'>
                        {[1, 2, 3, 4, 5].map(value => (
                            <div key={value} className='flex items-center'>
                                <div className={`mr-3 h-4 w-4 rounded-full bg-${getColorForValue(value)}`}></div>
                                <span className='text-sm text-gray-700 dark:text-gray-300'>
                                    {getLongDescription(value)}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='bg-gray-50 px-6 py-4 dark:bg-gray-800'>
                    <p className='text-sm text-gray-500 dark:text-gray-400'>
                        El promedio mostrado indica la tendencia general de las respuestas. Un promedio más alto indica
                        una opinión más positiva.
                    </p>
                </div>
            </div>
        </div>
    )
}
