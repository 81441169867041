import { createContext, useContext, useMemo, useState } from 'react'

import { useCollection } from '@/shared/firebase/hooks/useCollection'
import { useEventId } from '@/shared/useEventId'
import {
    LikertFeedback,
    MultiSelectFeedback,
    OptionsSliderFeedback,
    PersonFeedback,
    PersonFeedbacks,
    RadioFeedback,
    TextFeedback,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

export interface FilterItem {
    id: string
    questionTitle: string
    selectedValues: string[]
}

export interface Question {
    title: string
    type: 'SLIDER' | 'YES_NO' | 'RADIO' | 'MULTI_SELECT' | 'OPTIONS_SLIDER'
    options: { value: string; label: string }[]
}

interface FeedbackContextType {
    textFeedbacks: TextFeedback[]
    likertFeedbacks: LikertFeedback[]
    yesNoFeedbacks: YesNoFeedback[]
    optionsSliderFeedback: OptionsSliderFeedback[]
    multiSelectFeedback: MultiSelectFeedback[]
    radioFeedback: RadioFeedback[]
    activeFilters: FilterItem[]
    setActiveFilters: (filters: FilterItem[]) => void
    filteredFeedbacks: PersonFeedback[]
    isLoading: boolean
    availableQuestions: Question[]
}

const FeedbackContext = createContext<FeedbackContextType | undefined>(undefined)

export const useFeedback = () => {
    const context = useContext(FeedbackContext)
    if (!context) {
        throw new Error('useFeedback must be used within a FeedbackProvider')
    }
    return context
}

const isFreeText = (personFeedback: PersonFeedback): personFeedback is TextFeedback => {
    return personFeedback.question.type === 'FREE_TEXT'
}

const isLikert = (personFeedback: PersonFeedback): personFeedback is LikertFeedback => {
    return personFeedback.question.type === 'SLIDER'
}

const isYesNo = (personFeedback: PersonFeedback): personFeedback is YesNoFeedback => {
    return personFeedback.question.type === 'YES_NO'
}

const isOptionsSlider = (personFeedback: PersonFeedback): personFeedback is OptionsSliderFeedback => {
    return personFeedback.question.type === 'OPTIONS_SLIDER'
}

const isMultiSelectFeedback = (personFeedback: PersonFeedback): personFeedback is MultiSelectFeedback => {
    return personFeedback.question.type === 'MULTI_SELECT'
}

const isRadioFeedback = (personFeedback: PersonFeedback): personFeedback is RadioFeedback => {
    return personFeedback.question.type === 'RADIO'
}

const getLikertOptions = (feedbacks: LikertFeedback[]) => {
    const uniqueValues = Array.from(new Set(feedbacks.map(f => f.answer.value)))
    return uniqueValues
        .sort((a, b) => a - b)
        .map(value => ({
            value: value.toString(),
            label: value.toString(),
            description:
                value === 1
                    ? 'Muy en desacuerdo'
                    : value === 2
                      ? 'En desacuerdo'
                      : value === 3
                        ? 'Neutral'
                        : value === 4
                          ? 'De acuerdo'
                          : 'Muy de acuerdo',
        }))
}

export const FeedbackProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const eventId = useEventId()
    const [activeFilters, setActiveFilters] = useState<FilterItem[]>([])

    const { results: feedbacks, isLoading } = useCollection<PersonFeedbacks>({
        path: `events/${eventId}/feedback`,
        orderBy: ['createdAt'],
    })

    const flatFeedbacks = useMemo(() => feedbacks.flatMap(f => f.feedbacks), [feedbacks])

    // First calculate filteredFeedbacks at the user level
    const filteredFeedbacks = useMemo(() => {
        if (activeFilters.length === 0) return flatFeedbacks

        // Get only filters that have selected values
        const activeFiltersWithValues = activeFilters.filter(filter => filter.selectedValues.length > 0)

        // If no filters have selected values, return all feedbacks
        if (activeFiltersWithValues.length === 0) return flatFeedbacks

        // First group feedbacks by user
        const feedbacksByUser = feedbacks.map(userFeedback => ({
            userId: userFeedback.id,
            feedbacks: userFeedback.feedbacks,
        }))

        // Filter at user level
        const filteredUserFeedbacks = feedbacksByUser.filter(userFeedback => {
            return activeFiltersWithValues.every(filter => {
                // Find the specific feedback for this filter's question
                const matchingFeedback = userFeedback.feedbacks.find(
                    feedback => feedback.question.title === filter.questionTitle,
                )

                // If user didn't answer this question, exclude them
                if (!matchingFeedback) return false

                // Check if the user's answer matches the filter
                if (isLikert(matchingFeedback)) {
                    return filter.selectedValues.includes(matchingFeedback.answer.value.toString())
                }
                if (isYesNo(matchingFeedback)) {
                    return filter.selectedValues.includes(matchingFeedback.answer.value.toString())
                }
                if (isOptionsSlider(matchingFeedback) || isRadioFeedback(matchingFeedback)) {
                    return filter.selectedValues.includes(matchingFeedback.answer.value)
                }
                if (isMultiSelectFeedback(matchingFeedback)) {
                    return matchingFeedback.answer.value.some(value => filter.selectedValues.includes(value))
                }
                return false
            })
        })

        // Flatten back to individual feedbacks
        return filteredUserFeedbacks.flatMap(userFeedback => userFeedback.feedbacks)
    }, [feedbacks, activeFilters])

    // Then use filteredFeedbacks to calculate all other feedback types
    const textFeedbacks = useMemo(() => filteredFeedbacks.filter(isFreeText), [filteredFeedbacks])
    const likertFeedbacks = useMemo(() => filteredFeedbacks.filter(isLikert), [filteredFeedbacks])
    const yesNoFeedbacks = useMemo(() => filteredFeedbacks.filter(isYesNo), [filteredFeedbacks])
    const optionsSliderFeedback = useMemo(() => filteredFeedbacks.filter(isOptionsSlider), [filteredFeedbacks])
    const multiSelectFeedback = useMemo(() => filteredFeedbacks.filter(isMultiSelectFeedback), [filteredFeedbacks])
    const radioFeedback = useMemo(() => filteredFeedbacks.filter(isRadioFeedback), [filteredFeedbacks])

    // availableQuestions still uses flatFeedbacks since it needs all possible options
    const availableQuestions = useMemo(() => {
        const questions: Question[] = [
            // De likertFeedbacks
            ...Array.from(new Set(flatFeedbacks.filter(isLikert).map(f => f.question.title))).map(title => {
                const feedbacks = flatFeedbacks.filter(isLikert).filter(f => f.question.title === title)
                return {
                    title,
                    type: 'SLIDER',
                    options: getLikertOptions(feedbacks),
                }
            }),
            // De yesNoFeedbacks
            ...Array.from(new Set(flatFeedbacks.filter(isYesNo).map(f => f.question.title))).map(title => {
                const feedbacks = flatFeedbacks.filter(isYesNo).filter(f => f.question.title === title)
                const uniqueValues = new Set(feedbacks.map(f => f.answer.value))
                return {
                    title,
                    type: 'YES_NO',
                    options: Array.from(uniqueValues).map(value => ({
                        value: value.toString(),
                        label: value ? 'Sí' : 'No',
                    })),
                }
            }),
            // De radioFeedback
            ...Array.from(new Set(flatFeedbacks.filter(isRadioFeedback).map(f => f.question.title))).map(title => {
                const question = flatFeedbacks.filter(isRadioFeedback).find(f => f.question.title === title)?.question
                return {
                    title,
                    type: 'RADIO',
                    options: question?.options ?? [],
                }
            }),
            // De multiSelectFeedback
            ...Array.from(new Set(flatFeedbacks.filter(isMultiSelectFeedback).map(f => f.question.title))).map(
                title => {
                    const question = flatFeedbacks
                        .filter(isMultiSelectFeedback)
                        .find(f => f.question.title === title)?.question
                    return {
                        title,
                        type: 'MULTI_SELECT',
                        options: question?.options ?? [],
                    }
                },
            ),
            // De optionsSliderFeedback
            ...Array.from(new Set(flatFeedbacks.filter(isOptionsSlider).map(f => f.question.title))).map(title => {
                const question = flatFeedbacks.filter(isOptionsSlider).find(f => f.question.title === title)?.question
                return {
                    title,
                    type: 'OPTIONS_SLIDER',
                    options: question?.options ?? [],
                }
            }),
        ]
        return questions.filter(question => question.options && question.options.length > 0)
    }, [flatFeedbacks])

    const value = {
        textFeedbacks,
        likertFeedbacks,
        yesNoFeedbacks,
        optionsSliderFeedback,
        multiSelectFeedback,
        radioFeedback,
        activeFilters,
        setActiveFilters,
        filteredFeedbacks,
        isLoading,
        availableQuestions,
    }

    return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>
}
