import {
    AlertTriangle,
    Coffee,
    DollarSign,
    HelpCircle,
    Lightbulb,
    MessageSquare,
    Music,
    Shield,
    Thermometer,
    ThumbsUp,
    Users,
} from 'lucide-react'

import { Category } from '@monorepo/functions/src/types/feedback'

export const getCategoryColor = (category: Category) => {
    const colors = {
        Problemas: 'from-red-500 to-orange-500',
        Peticiones: 'from-blue-500 to-cyan-500',
        Soluciones: 'from-purple-500 to-pink-500',
        Fortalezas: 'from-green-500 to-emerald-500',
        Otros: 'from-yellow-500 to-amber-500',
    }
    return colors[category] || 'from-gray-500 to-gray-700'
}

export const categoryIcons = {
    Problemas: <AlertTriangle className='size-5' />,
    Peticiones: <MessageSquare className='size-5' />,
    Soluciones: <Lightbulb className='size-5' />,
    Fortalezas: <ThumbsUp className='size-5' />,
    Otros: <HelpCircle className='size-5' />,
}

export const departmentIcons = {
    Música: <Music className='h-4 w-4' />,
    Ambiente: <Thermometer className='h-4 w-4' />,
    Personal: <Users className='h-4 w-4' />,
    Seguridad: <Shield className='h-4 w-4' />,
    Servicios: <Coffee className='h-4 w-4' />,
    Pricing: <DollarSign className='h-4 w-4' />,
    Otros: <HelpCircle className='h-4 w-4' />,
}
