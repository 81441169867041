import { Dispatch, SetStateAction, useMemo, useState } from 'react'

import { ChevronDown, Search } from 'lucide-react'

import { Category, Department, TextFeedback } from '@monorepo/functions/src/types/feedback'

import { categories, departments } from './GenerateSampleMessages'
import { categoryIcons, departmentIcons, getCategoryColor } from './lib'

interface Props {
    selectedCategory: Category
    selectedDepartments: Department[]
    setSelectedDepartments: Dispatch<SetStateAction<Department[]>>
    selectedCategories: Category[]
    setSelectedCategories: Dispatch<SetStateAction<Category[]>>
    textFeedbacks: TextFeedback[]
}

export const CommentsSidebar = ({
    selectedCategory,
    textFeedbacks,
    selectedDepartments,
    setSelectedDepartments,
    selectedCategories,
    setSelectedCategories,
}: Props) => {
    const [searchQuery, setSearchQuery] = useState('')
    const [showCategoryDropdown, setShowCategoryDropdown] = useState(false)
    const [showDepartmentDropdown, setShowDepartmentDropdown] = useState(false)

    const filteredComments = useMemo(() => {
        return textFeedbacks.filter(comment => {
            const matchesCategory =
                selectedCategories.length === 0 ||
                comment.answer.categories.some(cat => selectedCategories.includes(cat))
            const matchesDepartment =
                selectedDepartments.length === 0 ||
                comment.answer.departments.some(dept => selectedDepartments.includes(dept))
            const matchesSearch = comment.answer.value.toLowerCase().includes(searchQuery.toLowerCase())
            return matchesCategory && matchesDepartment && matchesSearch
        })
    }, [textFeedbacks, selectedCategories, selectedDepartments, searchQuery])

    const toggleCategory = (category: Category) => {
        setSelectedCategories(prev =>
            prev.includes(category) ? prev.filter(c => c !== category) : [...prev, category],
        )
    }

    const toggleDepartment = (dept: Department) => {
        setSelectedDepartments(prev => (prev.includes(dept) ? prev.filter(d => d !== dept) : [...prev, dept]))
    }

    return (
        <div className='mb-4 w-2/6 overflow-y-auto p-6'>
            <h2 className='mb-6 bg-gradient-to-r from-purple-400 to-pink-600 bg-clip-text text-2xl font-bold text-transparent'>
                Comentarios
            </h2>
            <div className='mb-6'>
                <div className='relative'>
                    <input
                        type='text'
                        placeholder='Buscar comentarios...'
                        className='w-full rounded-lg bg-gray-800 bg-opacity-50 p-3 pl-10 shadow-inner backdrop-blur-sm'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                    <Search className='absolute left-3 top-3 text-gray-400' />
                </div>
            </div>
            <div className='mb-6 flex space-x-4'>
                <div className='relative w-1/2'>
                    <button
                        className='flex w-full items-center justify-between rounded-lg bg-gray-800 bg-opacity-50 p-3 shadow-lg backdrop-blur-sm transition-colors duration-300 hover:bg-gray-700'
                        onClick={() => setShowCategoryDropdown(!showCategoryDropdown)}
                    >
                        Categorías <ChevronDown />
                    </button>
                    {showCategoryDropdown && (
                        <div className='absolute left-0 top-full z-10 mt-1 w-full rounded-lg bg-gray-800 bg-opacity-90 shadow-xl backdrop-blur-sm'>
                            {categories.map(category => (
                                <div
                                    key={category}
                                    className={`flex w-full cursor-pointer flex-row items-center gap-3 p-2 transition-all duration-300 ${
                                        selectedCategories.includes(category)
                                            ? `bg-gradient-to-r ${getCategoryColor(category)}`
                                            : 'hover:bg-gray-700'
                                    }`}
                                    onClick={() => toggleCategory(category)}
                                >
                                    <span>{categoryIcons[category]}</span>
                                    {category}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <div className='relative w-1/2'>
                    <button
                        className='flex w-full items-center justify-between rounded-lg bg-gray-800 bg-opacity-50 p-3 shadow-lg backdrop-blur-sm transition-colors duration-300 hover:bg-gray-700'
                        onClick={() => setShowDepartmentDropdown(!showDepartmentDropdown)}
                    >
                        Departamentos <ChevronDown />
                    </button>
                    {showDepartmentDropdown && (
                        <div className='absolute left-0 top-full z-10 mt-1 w-full rounded-lg bg-gray-800 bg-opacity-90 shadow-xl backdrop-blur-sm'>
                            {departments.map(dept => (
                                <div
                                    key={dept}
                                    className={`flex w-full cursor-pointer flex-row items-center gap-2 p-2 transition-all duration-300 ${
                                        selectedDepartments.includes(dept)
                                            ? `bg-gradient-to-r ${getCategoryColor(selectedCategory)}`
                                            : 'hover:bg-gray-700'
                                    }`}
                                    onClick={() => toggleDepartment(dept)}
                                >
                                    <span className='mr-2'>{departmentIcons[dept]}</span>
                                    {dept}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className='space-y-4'>
                {filteredComments
                    .sort((a, b) => b.answer.value.length - a.answer.value.length)
                    .map((comment, i) => (
                        <div
                            key={i + comment.question.title}
                            className='flex w-full flex-col gap-2 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-lg backdrop-blur-sm transition-all duration-300 hover:scale-105'
                        >
                            <h4 className='bg-clip-text font-semibold text-pink-500'>{comment.question.title}</h4>
                            {/* categories and departments as daisyui badge */}
                            <div className='flex flex-wrap gap-2'>
                                {comment.answer.categories.map(cat => (
                                    <span key={cat} className={`badge badge-primary badge-outline rounded-full`}>
                                        {cat}
                                    </span>
                                ))}
                                {comment.answer.departments.map(dept => (
                                    <span key={dept} className='badge badge-primary badge-outline rounded-full'>
                                        {dept}
                                    </span>
                                ))}
                            </div>
                            <p>{comment.answer.value}</p>
                        </div>
                    ))}
            </div>
        </div>
    )
}
