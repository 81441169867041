import { motion } from 'framer-motion'

import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { Button } from '@/shared/ui/button.tsx'
import { YesNoQuestion } from '@monorepo/functions/src/types/feedback'

const buttonVariants = {
    initial: { scale: 1 },
    pressed: { scale: 0.95 },
}

const FormButton = motion(Button)

export const YesNoFormStep = ({ question, onNextStep }: BaseFormStepProps<YesNoQuestion>) => {
    const { setAnswer, answer } = useEventFeedbackAnswer(question)
    const setFieldValue = (value: boolean) =>
        setAnswer({
            type: 'YES_NO',
            value,
            departments: question.departments,
            categories: question.categories,
        })

    const handleNoClick = () => {
        setFieldValue(false)
        onNextStep()
    }

    const handleYesClick = () => {
        setFieldValue(true)
        onNextStep()
    }

    return (
        <>
            <h2 className='text-center text-xl font-bold'>{question.title}</h2>
            <div className='mt-4 flex justify-center space-x-4'>
                <FormButton
                    type='button'
                    variants={buttonVariants}
                    initial='initial'
                    whileTap='pressed'
                    size='lg'
                    onClick={handleNoClick}
                    active={answer?.value === false}
                >
                    No
                </FormButton>
                <FormButton
                    type='button'
                    variants={buttonVariants}
                    initial='initial'
                    whileTap='pressed'
                    size='lg'
                    onClick={handleYesClick}
                    active={answer?.value === true}
                >
                    Sí
                </FormButton>
            </div>
        </>
    )
}
