// Add a custom renderer for links
import { marked } from 'marked'

const renderer = new marked.Renderer()
renderer.link = ({ href, title, text }) => {
    return `<a href="${href}" title="${title || ''}" class="text-blue-400 hover:underline" target="_blank" rel="noopener noreferrer">${text}</a>`
}

marked.setOptions({
    renderer: renderer,
    breaks: true,
})
