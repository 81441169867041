import { ReactNode } from 'react'

import { Users } from 'lucide-react'

interface Props {
    questionTitle: string
    numberOfFeedbacks: number
    children: ReactNode
}

export const InsightsCard = ({ questionTitle, numberOfFeedbacks, children }: Props) => (
    <div className='w-full'>
        <div className='flex h-full flex-col gap-4 rounded-lg bg-gray-800 bg-opacity-50 p-4 shadow-xl backdrop-blur-sm'>
            <div className='flex items-start justify-between gap-4'>
                <h4 className='font-semibold'>{questionTitle}</h4>
                <div className='flex items-center gap-1 pt-0.5'>
                    <Users className='mr-1 h-4 w-4' />
                    <span className='text-sm'>{numberOfFeedbacks}</span>
                </div>
            </div>
            <div className='flex w-full items-center justify-center'>{children}</div>
        </div>
    </div>
)
