import { ReactNode } from 'react'

import { AuthProvider } from '@/shared/auth.tsx'

import { ThemeProvider } from './styles/theme-provider'

export const UnauthenticatedProviders = ({ children }: { children: ReactNode }) => {
    return (
        <AuthProvider>
            <ThemeProvider defaultTheme='dark' storageKey='ui-theme'>
                {children}
            </ThemeProvider>
        </AuthProvider>
    )
}
