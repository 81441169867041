import { motion } from 'framer-motion'

import BimeBackgroundLetters from '@/pages/form/assets/img/bime-background-letters.svg?react'
import BimeBareLogo from '@/pages/form/assets/img/bime-bare-logo.png'
import BimeLogo from '@/pages/form/assets/img/bime-logo-black.png'
import { PoweredBy } from '@/pages/form/components/PoweredBy.tsx'

import { EventFormOnboardingProps } from '../types.ts'
import { Cross } from './Cross.tsx'

export const FormOnboarding: React.FC<EventFormOnboardingProps> = ({ onStartForm }) => (
    <>
        <motion.div
            initial='hidden'
            animate={{
                opacity: [0, 1, 1, 1, 1, 1, 1, 0],
                y: ['0vh', '20vh', '20vh', '30vh', '30vh', '20vh', '20vh', '0vh'],
                x: [0, 0, 0, '10vw', '10vw', 0, 0, 0],
            }}
            transition={{
                delay: 1,
                duration: 7,
                repeat: Infinity,
                repeatType: 'loop',
                ease: 'easeInOut',
            }}
            className='absolute left-0 top-0 z-0 w-full'
        >
            <BimeBackgroundLetters className='w-full' />
        </motion.div>
        <motion.div
            initial='hidden'
            animate={{
                opacity: [0, 1, 1, 1, 1, 1, 1, 0],
                y: ['0vh', '20vh', '20vh', '30vh', '30vh', '20vh', '20vh', '0vh'],
                x: [0, 0, 0, '10vw', '10vw', 0, 0, 0],
            }}
            transition={{
                delay: 1,
                duration: 7,
                repeat: Infinity,
                repeatType: 'loop',
                ease: 'easeInOut',
            }}
            className='absolute left-1 top-1 z-0 w-full'
        >
            <BimeBackgroundLetters className='w-full' />
        </motion.div>
        <div className='z-10 flex flex-col items-center justify-center'>
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className='mb-8 text-center font-bime-knockout-welter text-3xl tracking-wide text-secondary-foreground'
            >
                <span className='font-bime-knockout-hevi'>C</span>
                UÉN
                <span className='font-bime-knockout-bantam'>TA</span>
                <span className='font-bime-knockout-hevi'>N</span>
                OS TU <span className='font-bime-knockout-bantam'>EX</span>P
                <span className='font-bime-knockout-cruiser'>E</span>
                <span className='font-bime-knockout-feather'>RI</span>
                <span className='font-bime-knockout-cruiser'>E</span>N<span className='font-bime-knockout-fly'>CI</span>
                A
            </motion.h1>
            <motion.div
                animate={{
                    scale: [0.5, 1, 1.2],
                }}
                transition={{ duration: 1 }}
                className='relative mb-8'
            >
                <img className='relative z-10 h-20' src={BimeLogo} alt='Logo BIME' />
                <Cross className='absolute top-1/2 z-0 animate-[crossAnimation_5s_ease-in-out_infinite]' />
            </motion.div>
            <div className='z-10 mx-4 mb-6 w-full max-w-sm text-center'>
                <motion.h2
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.6, duration: 0.5 }}
                    className='mb-4 text-xl font-semibold text-secondary-foreground'
                >
                    IMPULSA EL FUTURO DE LA MÚSICA CON TU OPINIÓN
                </motion.h2>
                <motion.p
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.8, duration: 0.5 }}
                    className='text-md'
                >
                    Haz que el próximo{' '}
                    <span>
                        <img className='inline h-3' src={BimeBareLogo} alt='BIME logo' />
                    </span>{' '}
                    sea aún más inspirador
                </motion.p>
            </div>
            <motion.button
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.9, duration: 0.5 }}
                onClick={onStartForm}
                className='mb-8 transform bg-black px-8 py-3 font-bold text-white transition duration-300 ease-in-out hover:scale-105 hover:bg-black/80'
            >
                COMENZAR
            </motion.button>
            <PoweredBy />
            {/* <Cross
                    className='absolute bottom-9 left-1/3 z-0 animate-[crossAnimation_5s_ease-in-out_infinite] delay-500'
                    crossXClassName='h-4 w-16 bg-muted-foreground'
                    crossYClassName='h-16 w-4 bg-muted-foreground'
                />
                <Cross
                    className='absolute bottom-28 left-1/3 z-0 animate-[crossAnimation_5s_ease-in-out_infinite] delay-500'
                    crossXClassName='h-4 w-16 bg-muted-foreground'
                    crossYClassName='h-16 w-4 bg-muted-foreground'
                /> */}
        </div>
    </>
)
