import { DocumentData, collection, doc, getDoc, onSnapshot, setDoc } from 'firebase/firestore'

import { firestore } from './firebase.ts'
import { FSDocument } from './types.ts'

export interface GetDocumentParams<T> {
    collectionName: string
    id?: string
    onDocumentChange?: (doc: T) => void
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

const mapNullToUndefined = <T extends DocumentData>(data: T) =>
    Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: data[key] === null ? undefined : data[key] }), {})

export const getDocument = async <T extends FSDocument>({
    collectionName,
    id,
    onDocumentChange,
}: WithRequired<GetDocumentParams<T>, 'id'>): Promise<T | undefined> => {
    const docRef = doc(firestore, collectionName, id)
    const snapshot = await getDoc(docRef)
    if (!snapshot.exists()) return undefined

    if (onDocumentChange) {
        onSnapshot(docRef, docSnapshot => {
            if (docSnapshot.exists()) {
                onDocumentChange({ ...mapNullToUndefined(docSnapshot.data()), id: docSnapshot.id } as T)
            }
        })
    }

    return { ...mapNullToUndefined(snapshot.data()), id: snapshot.id } as T
}

export interface SetDocumentParams<T> {
    collectionName: string
    id?: string
    data: Partial<T>
}

const mapUndefinedToNull = <T extends DocumentData>(data: T) =>
    Object.keys(data).reduce((acc, key) => ({ ...acc, [key]: data[key] === undefined ? null : data[key] }), {})

export const setDocument = async <T extends DocumentData>({
    collectionName,
    id,
    data,
}: SetDocumentParams<T>): Promise<void> => {
    if (id) {
        // either the doc exists or we want to create it with a specific id
        const docRef = doc(firestore, collectionName, id)
        await setDoc(docRef, mapUndefinedToNull(data), { merge: true })
    } else {
        // the doc does not exist, an id will be autogenerated
        const docRef = doc(collection(firestore, collectionName))
        await setDoc(docRef, mapUndefinedToNull(data), { merge: true })
    }
}
