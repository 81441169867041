import React, { createContext, useContext, useEffect, useRef, useState } from 'react'

interface Splash {
    x: number
    y: number
    radius: number
    opacity: number
}

interface CanvasContextProps {
    createDrop: () => void
    removeWater: (amount: number) => void
    resetCanvas: () => void
    showCanvas: () => void
    hideCanvas: () => void
}

const FillWaterAnimationContext = createContext<CanvasContextProps | undefined>(undefined)

export const useFillWaterAnimation = () => {
    const context = useContext(FillWaterAnimationContext)
    if (!context) {
        throw new Error('useCanvas must be used within a CanvasProvider')
    }
    return context
}

const DEFAULT_WATER_COLOR = 'rgba(0, 120, 255, 0.6)'

const getWaterColor = (): string => {
    const colorDiv = document.getElementById('colorDiv')
    let color = DEFAULT_WATER_COLOR
    if (colorDiv) color = window.getComputedStyle(colorDiv).backgroundColor

    return color
}

export const FillWaterAnimationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const ctxRef = useRef<CanvasRenderingContext2D | null>(null)
    const waterLevelRef = useRef(0)
    const targetWaterLevelRef = useRef(0)
    const dropsRef = useRef<{ x: number; y: number; speed: number; size: number }[]>([])
    const splashesRef = useRef<Splash[]>([])
    const canvasDimensionsRef = useRef({ width: 0, height: 0 })
    const waveOffsetRef = useRef(0)
    const animationRef = useRef<number>()
    const [isCanvasVisible, setCanvasVisible] = useState(false)

    useEffect(() => {
        const canvas = canvasRef.current
        if (!canvas) return

        const ctx = canvas.getContext('2d')
        if (!ctx) return

        ctxRef.current = ctx

        const updateCanvasDimensions = () => {
            if (canvas) {
                canvas.width = window.innerWidth
                canvas.height = window.innerHeight
                canvasDimensionsRef.current = { width: canvas.width, height: canvas.height }
            }
        }

        updateCanvasDimensions()
        window.addEventListener('resize', updateCanvasDimensions)

        const animate = () => {
            if (ctxRef.current && isCanvasVisible) {
                drawScene(ctxRef.current)
            }
            animationRef.current = requestAnimationFrame(animate)
        }

        animate()

        return () => {
            if (animationRef.current) {
                cancelAnimationFrame(animationRef.current)
            }
            window.removeEventListener('resize', updateCanvasDimensions)
        }
    }, [isCanvasVisible])

    const createDrop = () => {
        dropsRef.current.push({
            x: Math.random() * canvasDimensionsRef.current.width,
            y: 0,
            speed: 6 + Math.random() * 12,
            size: 4 + Math.random() * 6,
        })
    }

    const removeWater = (amount: number) => {
        targetWaterLevelRef.current = Math.max(
            targetWaterLevelRef.current - (amount * canvasDimensionsRef.current.height) / 100,
            0,
        )
    }

    const createSplash = (x: number, y: number) => {
        splashesRef.current.push({
            x,
            y,
            radius: 0,
            opacity: 1,
        })
    }

    const drawWaves = (ctx: CanvasRenderingContext2D, yOffset: number) => {
        ctx.beginPath()
        ctx.moveTo(0, canvasDimensionsRef.current.height)
        for (let x = 0; x < canvasDimensionsRef.current.width; x++) {
            const y = Math.sin(x * 0.01 + waveOffsetRef.current) * 5 + yOffset
            ctx.lineTo(x, y)
        }
        ctx.lineTo(canvasDimensionsRef.current.width, canvasDimensionsRef.current.height)
        ctx.fillStyle = getWaterColor()
        ctx.fill()
    }

    const drawWaterDrop = (ctx: CanvasRenderingContext2D, x: number, y: number, size: number) => {
        ctx.beginPath()
        ctx.moveTo(x, y)
        ctx.bezierCurveTo(x - size, y - size * 2, x - size, y - size / 2, x, y - size * 3)
        ctx.bezierCurveTo(x + size, y - size / 2, x + size, y - size * 2, x, y)
        ctx.fillStyle = getWaterColor()
        ctx.fill()
    }

    const drawSplashes = (ctx: CanvasRenderingContext2D) => {
        splashesRef.current.forEach((splash, index) => {
            ctx.beginPath()
            ctx.arc(splash.x, splash.y, splash.radius, 0, Math.PI * 2)
            ctx.strokeStyle = `rgba(0, 120, 255, ${splash.opacity})`
            ctx.stroke()

            splash.radius += 0.5
            splash.opacity -= 0.03

            if (splash.opacity <= 0) {
                splashesRef.current.splice(index, 1)
            }
        })
    }

    const drawScene = (ctx: CanvasRenderingContext2D) => {
        ctx.clearRect(0, 0, canvasDimensionsRef.current.width, canvasDimensionsRef.current.height)

        // Smooth water level rising
        const diff = targetWaterLevelRef.current - waterLevelRef.current
        waterLevelRef.current += diff * 0.05

        drawWaves(ctx, canvasDimensionsRef.current.height - waterLevelRef.current)

        dropsRef.current.forEach((drop, index) => {
            drawWaterDrop(ctx, drop.x, drop.y, drop.size)

            drop.y += drop.speed

            if (drop.y > canvasDimensionsRef.current.height - waterLevelRef.current) {
                createSplash(drop.x, canvasDimensionsRef.current.height - waterLevelRef.current)
                dropsRef.current.splice(index, 1)
                targetWaterLevelRef.current = Math.min(
                    targetWaterLevelRef.current + canvasDimensionsRef.current.height / 100,
                    canvasDimensionsRef.current.height,
                )
            }
        })

        drawSplashes(ctx)

        waveOffsetRef.current += 0.05
    }

    const resetCanvas = () => {
        dropsRef.current = []
        splashesRef.current = []
        waterLevelRef.current = 0
        targetWaterLevelRef.current = 0
    }

    const showCanvas = () => setCanvasVisible(true)
    const hideCanvas = () => setCanvasVisible(false)

    return (
        <FillWaterAnimationContext.Provider value={{ createDrop, removeWater, resetCanvas, showCanvas, hideCanvas }}>
            {isCanvasVisible && (
                <>
                    <div id='colorDiv' className='hidden bg-water'></div>
                    <canvas
                        ref={canvasRef}
                        className='pointer-events-none absolute inset-0 h-full w-full'
                        aria-hidden='true'
                    />
                </>
            )}
            {children}
        </FillWaterAnimationContext.Provider>
    )
}
