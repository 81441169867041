import { BaseFormStepProps } from '@/pages/form/types'
import { useEventFeedbackAnswer } from '@/pages/form/useEventFeedback.ts'
import { Slider } from '@/shared/ui/slider.tsx'
import { LikertQuestion } from '@monorepo/functions/src/types/feedback'

export const SliderFormStep = ({ question, onNextStep }: BaseFormStepProps<LikertQuestion>) => {
    const { answer, setAnswer } = useEventFeedbackAnswer(question)

    const onValueChange = ([value]: [number]) => {
        setAnswer({
            type: 'SLIDER',
            value,
            departments: question.departments,
            categories: question.categories,
        })
    }

    return (
        <div className='flex flex-col items-center space-y-6'>
            <h2 className='text-center text-xl font-bold'>{question.title}</h2>
            <div className='w-80 space-y-4'>
                <Slider
                    min={1}
                    max={5}
                    step={1}
                    name={question.title}
                    className='w-full'
                    onValueChange={onValueChange}
                    onValueCommit={onNextStep}
                    value={answer ? [answer?.value] : [1]}
                />
                <div className='flex justify-between px-2 text-xs'>
                    {[1, 2, 3, 4, 5].map(value => (
                        <span key={value} className='w-6 text-center'>
                            {value}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    )
}
