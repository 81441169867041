import { PieChart, PieValueType } from '@mui/x-charts'
import { MakeOptional } from '@mui/x-charts/internals'

interface Props {
    data: MakeOptional<PieValueType, 'id'>[]
}

export const InsightsPieChart = ({ data }: Props) => {
    return (
        <PieChart
            series={[
                {
                    data: data.filter(item => item.value > 0),
                    innerRadius: 30,
                    outerRadius: 100,
                    paddingAngle: data.filter(item => item.value > 0).length > 1 ? 5 : 0,
                    cornerRadius: 5,
                    startAngle: -90,
                    endAngle: 270,
                    arcLabel: item =>
                        `${((item.value / data.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(0)}%`,
                    cx: '50%',
                    cy: '50%',
                },
            ]}
            margin={{ left: 100 }}
            width={200}
            height={200}
            slotProps={{
                legend: {
                    hidden: true,
                },
            }}
        />
    )
}
