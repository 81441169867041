import { useEffect } from 'react'
import GoogleButton from 'react-google-button'
import { Navigate, useSearchParams } from 'react-router-dom'

import { useAuth } from '@/shared/auth.tsx'
import { GoogleAuthProvider, auth, signInWithPopup } from '@/shared/firebase/firebase.ts'
import { TrackingService } from '@/shared/lib/tracking'

function LoginButton(props: { onClick: () => Promise<void> }) {
    return (
        <div className='flex h-screen flex-col items-center justify-center overflow-hidden bg-gradient-to-br from-gray-900 to-black text-white'>
            <h1 className='mb-4 text-center text-3xl font-bold text-primary'>Bienvenido a BoothBits</h1>
            <p className='mb-6 text-center text-gray-600'>
                Inicia sesión con Google para poder poder empezar a mejorar tus eventos
            </p>

            <iframe
                id='lottie-iframe'
                className='mb-6 hidden h-[15rem]'
                src='https://lottie.host/embed/011693f7-57ea-4af2-9d4f-6ff5dc30b7dd/jf5hCckDNw.json'
                onLoad={() => document.getElementById('lottie-iframe')!.classList.remove('hidden')}
            />
            <GoogleButton onClick={props.onClick} label='Inicia sesión con Google' />
        </div>
    )
}

export const LoginPage = () => {
    const { user } = useAuth()
    const [searchParams] = useSearchParams()
    const redirect = searchParams.get('redirect')

    useEffect(() => {
        TrackingService.trackPageView({
            page: 'login',
        })
    }, [])

    const handleLogin = async () => {
        try {
            await signInWithPopup(auth, new GoogleAuthProvider())
        } catch (error) {
            console.error('Error logging in:', error)
        }
    }

    if (user && !user.isAnonymous) return <Navigate to={redirect ?? '/demo/dashboard'} />

    return <LoginButton onClick={handleLogin} />
}
