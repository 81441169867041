import { useEffect, useRef, useState } from 'react'

import { Filter, Search, X } from 'lucide-react'

import { Button } from '@/shared/ui/button'

import { MultiSelectDropdown } from './MultiSelectDropdown'

interface Question {
    title: string
    type: 'SLIDER' | 'YES_NO' | 'RADIO' | 'MULTI_SELECT' | 'OPTIONS_SLIDER'
    options?: { value: string; label: string }[]
}

interface FilterItem {
    id: string
    questionTitle: string
    selectedValues: string[]
}

interface FilterSectionProps {
    onFiltersChange: (filters: FilterItem[]) => void
    availableQuestions: Question[]
}

const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text
    return text.slice(0, maxLength) + '...'
}

export const FilterSection = ({ onFiltersChange, availableQuestions }: FilterSectionProps) => {
    const [filters, setFilters] = useState<FilterItem[]>([])
    console.log('filters', filters)
    const [isAddingFilter, setIsAddingFilter] = useState(false)
    const [searchTerm, setSearchTerm] = useState('')
    const addFilterRef = useRef<HTMLDivElement>(null)
    const [hoveredFilter, setHoveredFilter] = useState<string | null>(null)
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 })

    useEffect(() => {
        console.log('filters', filters)
    }, [filters])

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (addFilterRef.current && !addFilterRef.current.contains(event.target as Node)) {
                setIsAddingFilter(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    const addFilter = (question: Question) => {
        const newFilter: FilterItem = {
            id: crypto.randomUUID(),
            questionTitle: question.title,
            selectedValues: [],
        }
        setFilters([...filters, newFilter])
        onFiltersChange([...filters, newFilter])
        setIsAddingFilter(false)
    }

    const removeFilter = (id: string) => {
        setHoveredFilter(null)
        const updatedFilters = filters.filter(f => f.id !== id)
        setFilters(updatedFilters)
        onFiltersChange(updatedFilters)
    }

    const updateFilter = (id: string, selectedValues: string[]) => {
        console.log('updateFilter', id, selectedValues)
        const updatedFilters = filters.map(f => (f.id === id ? { ...f, selectedValues } : f))
        setFilters(updatedFilters)
        console.log('updatedFilters', updatedFilters)
        onFiltersChange(updatedFilters)
    }

    const filteredQuestions = availableQuestions.filter(
        q =>
            q.title.toLowerCase().includes(searchTerm.toLowerCase()) && !filters.some(f => f.questionTitle === q.title),
    )

    const handleFilterMouseEnter = (event: React.MouseEvent, questionTitle: string) => {
        const rect = event.currentTarget.getBoundingClientRect()
        setTooltipPosition({
            top: rect.top - 8, // 8px arriba del filtro
            left: rect.left + rect.width / 2, // centrado horizontalmente
        })
        setHoveredFilter(questionTitle)
    }

    return (
        <div className='flex h-full flex-col'>
            <div className='flex min-h-[40px] items-center gap-2 py-2'>
                <div className='relative' ref={addFilterRef}>
                    <Button
                        variant='outline'
                        className='flex h-8 shrink-0 items-center gap-2 rounded-md border border-gray-700 bg-gray-900 px-3 text-sm text-gray-400 hover:bg-gray-800'
                        onClick={() => setIsAddingFilter(!isAddingFilter)}
                    >
                        <Filter className='h-4 w-4' />
                        Filtrar
                    </Button>

                    {isAddingFilter && (
                        <div className='absolute left-0 top-full z-[60] mt-1 min-w-[300px] overflow-hidden rounded-md border border-gray-700 bg-gray-900 shadow-lg'>
                            <div className='border-b border-gray-700 p-2'>
                                <div className='flex items-center gap-2 rounded-sm bg-gray-800 px-2 py-1'>
                                    <Search className='h-4 w-4 text-gray-400' />
                                    <input
                                        type='text'
                                        className='w-full bg-transparent text-sm text-white placeholder-gray-400 focus:outline-none'
                                        placeholder='Buscar pregunta...'
                                        value={searchTerm}
                                        onChange={e => setSearchTerm(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='max-h-60 overflow-auto py-1'>
                                {filteredQuestions.map(question => (
                                    <button
                                        key={question.title}
                                        className='flex w-full items-start px-3 py-1.5 text-left text-sm text-gray-300 hover:bg-gray-800'
                                        onClick={() => addFilter(question)}
                                    >
                                        {question.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                <div className='flex-1 overflow-x-auto'>
                    <div className='flex items-center gap-2 pr-4'>
                        {filters.map(filter => {
                            const question = availableQuestions.find(q => q.title === filter.questionTitle)
                            if (!question?.options) return null

                            return (
                                <div
                                    key={filter.id}
                                    className='relative flex h-8 shrink-0 items-center gap-1 rounded-md border border-gray-700 bg-gray-900 px-2'
                                    onMouseEnter={e => handleFilterMouseEnter(e, filter.questionTitle)}
                                    onMouseLeave={() => setHoveredFilter(null)}
                                >
                                    <div className='flex items-center gap-1'>
                                        <MultiSelectDropdown
                                            trigger={
                                                <button
                                                    className='text-sm text-gray-200 hover:text-white'
                                                    title={filter.questionTitle}
                                                >
                                                    {truncateText(filter.questionTitle, 25)}
                                                </button>
                                            }
                                            options={question.options}
                                            selectedValues={filter.selectedValues}
                                            onChange={values => updateFilter(filter.id, values)}
                                            placeholder='Select options...'
                                        />
                                        {filter.selectedValues.length > 0 && (
                                            <>
                                                <span className='text-gray-500'>:</span>
                                                <div className='flex items-center gap-1'>
                                                    {(() => {
                                                        const selectedOptions = question.options.filter(opt =>
                                                            filter.selectedValues.includes(opt.value),
                                                        )

                                                        if (selectedOptions.length === 0) return null

                                                        const displayText = selectedOptions
                                                            .map(opt => opt.label)
                                                            .join(', ')

                                                        return (
                                                            <span className='text-sm text-gray-400' title={displayText}>
                                                                {truncateText(displayText, 30)}
                                                            </span>
                                                        )
                                                    })()}
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <Button
                                        variant='ghost'
                                        size='sm'
                                        className='ml-2 h-6 w-6 shrink-0 p-0 text-gray-500 hover:bg-gray-800 hover:text-gray-300'
                                        onClick={() => removeFilter(filter.id)}
                                    >
                                        <X className='h-3 w-3' />
                                    </Button>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* Tooltip */}
            {hoveredFilter && (
                <div
                    className='pointer-events-none fixed z-[80] -translate-x-1/2 -translate-y-full'
                    style={{
                        top: `${tooltipPosition.top}px`,
                        left: `${tooltipPosition.left}px`,
                    }}
                >
                    <div className='relative'>
                        <div className='max-w-md rounded bg-gray-800 px-3 py-2 text-sm text-white shadow-xl ring-1 ring-gray-700/50'>
                            {hoveredFilter}
                        </div>
                        <div className='absolute left-1/2 top-full -translate-x-1/2 border-4 border-transparent border-t-gray-800' />
                    </div>
                </div>
            )}
        </div>
    )
}
