import { httpsCallable } from 'firebase/functions'

import { functions } from '@/shared/firebase/firebase.ts'
import {
    SummarizeAllFeedbacksRequest,
    SummarizeAllFeedbacksResponse,
} from '@monorepo/functions/src/types/SummarizeAllFeedbacks'

export const summarizeAllFeedbacksCloudFn = httpsCallable<SummarizeAllFeedbacksRequest, SummarizeAllFeedbacksResponse>(
    functions,
    'summarizeAllFeedbacks',
)

export const summarizeAllFeedbacks = (req: SummarizeAllFeedbacksRequest) =>
    summarizeAllFeedbacksCloudFn(req).then(res => res.data)
