import { FirestoreDataConverter, collection, doc } from 'firebase/firestore'

import { firestore } from '@/shared/firebase/firebase.ts'
import { Summaries } from '@monorepo/functions/src/types/event'

//TODO HOW DO WE SHARE CONVERTERS BETWEEN FRONTEND AND BACKEND?
const summariesConverter: FirestoreDataConverter<Summaries> = {
    // Removing id from the object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toFirestore: ({ id, ...summaries }) => summaries,
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options)
        return { id: snapshot.id, ...data } as Summaries
    },
}

export function getSummariesDocRef(eventId: string, language: string = 'default') {
    return doc(collection(firestore, `events/${eventId}/summaries`), language).withConverter(summariesConverter)
}
