import { QuestionType } from '@/pages/form/types'
import {
    Category,
    Department,
    LikertFeedback,
    Question,
    TextFeedback,
    TextQuestion,
    YesNoFeedback,
} from '@monorepo/functions/src/types/feedback'

export const categories: Category[] = ['Problemas', 'Fortalezas', 'Peticiones', 'Soluciones', 'Otros']
export const departments: Department[] = ['Música', 'Ambiente', 'Personal', 'Seguridad', 'Servicios', 'Pricing']

const generateQuestion = <T extends QuestionType>(
    title: string,
    type: T,
    departments: Department[],
    categories: Category[],
): Extract<Question, { type: T }> =>
    ({
        title,
        type,
        departments,
        categories,
    }) as Extract<Question, { type: T }>

const sampleQuestions: TextQuestion[] = [
    generateQuestion(
        '¿Tienes alguna sugerencia respecto a la ambientación de la fiesta? Decoración, rollo de la gente, espectáculo…',
        'FREE_TEXT',
        ['Ambiente', 'Música'],
        ['Peticiones', 'Soluciones'],
    ),
    generateQuestion('¿Qué te ha parecido el personal del evento?', 'FREE_TEXT', ['Personal'], []),
    generateQuestion(
        '¿Qué mejorarías del evento?',
        'FREE_TEXT',
        ['Ambiente', 'Música', 'Personal', 'Seguridad', 'Servicios', 'Pricing'],
        ['Peticiones', 'Problemas'],
    ),
    generateQuestion(
        '¿Qué es lo que más te ha gustado del evento?',
        'FREE_TEXT',
        ['Ambiente', 'Música', 'Personal', 'Seguridad', 'Servicios', 'Pricing'],
        ['Fortalezas'],
    ),
    generateQuestion('¿Qué opinas de la música?', 'FREE_TEXT', ['Música'], []),
]

const sampleMessages: TextFeedback[] = [
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me encantó la decoración, pero creo que podrían añadir más efectos visuales durante los espectáculos, como luces o proyecciones. Eso haría el ambiente aún más inmersivo.',
            departments: ['Ambiente', 'Música'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El ambiente era genial, pero creo que hacía falta más espacio para relajarse. Un área de descanso con sofás o pufs estaría perfecto.',
            departments: ['Ambiente', 'Servicios'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La decoración me pareció muy adecuada al tema de la fiesta. Las luces y el espectáculo también fueron buenos, pero creo que la música debería estar más sincronizada con los momentos de los espectáculos.',
            departments: ['Ambiente', 'Música'],
            categories: ['Peticiones', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Todo estuvo muy bien, pero creo que la fiesta necesitaba un poco más de interacción con el público. Quizás podrían incorporar más animadores.',
            departments: ['Ambiente', 'Personal'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El ambiente era increíble, pero la decoración podría ser más innovadora. Un toque más futurista o temático sería genial para la próxima vez.',
            departments: ['Ambiente'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La fiesta estuvo increíble, la ambientación me gustó mucho. Pero creo que el espectáculo fue un poco corto, podrían hacer más shows durante la noche.',
            departments: ['Ambiente', 'Música'],
            categories: ['Peticiones', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El público estaba animado y la decoración fue acertada. Sin embargo, el espectáculo podría haber sido más emocionante, algo que atrape más la atención.',
            departments: ['Ambiente', 'Música'],
            categories: ['Peticiones', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La ambientación en general fue buena, pero creo que faltó algo de interacción con el público, como más juegos o actividades entre las presentaciones.',
            departments: ['Ambiente', 'Personal'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El personal fue muy atento y amable en todo momento. Estuvieron siempre disponibles para ayudar con lo que necesitáramos.',
            departments: ['Personal'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me parece que el equipo fue muy profesional, pero en algunos momentos había muy pocos atendiendo en la barra, lo que generó un poco de espera.',
            departments: ['Personal', 'Servicios'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Todos fueron muy amables, desde los de seguridad hasta el personal de la barra. ¡Gran servicio!',
            departments: ['Personal', 'Seguridad'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El trato fue correcto, aunque en algún momento parecía que estaban un poco desbordados con la cantidad de gente.',
            departments: ['Personal'],
            categories: ['Problemas', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Muy amables, pero creo que en la entrada faltaba alguien para guiar mejor a la gente. Hubo un poco de confusión al acceder.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El personal del evento fue fantástico, siempre con una sonrisa y dispuestos a ayudar. Muy bien organizado.',
            departments: ['Personal'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El servicio estuvo bien, aunque creo que la espera en la barra fue un poco larga. Más personal aquí sería ideal.',
            departments: ['Personal', 'Servicios'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El equipo fue muy amable y paciente, especialmente cuando pedimos ayuda para encontrar nuestras ubicaciones.',
            departments: ['Personal'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Definitivamente la organización del acceso. Hubo bastante desorden en la entrada y eso generó colas largas.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me parece que el evento fue muy bueno, pero creo que faltó más variedad en la música. Un poco más de géneros sería perfecto.',
            departments: ['Música'],
            categories: ['Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Hubo momentos en que la pista de baile estaba muy llena, quizás más espacio o controlar el aforo sería una mejora.',
            departments: ['Ambiente', 'Seguridad'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La comida fue algo limitada. Creo que deberían ofrecer más opciones o, al menos, tener un servicio más rápido.',
            departments: ['Servicios'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El sonido era un poco bajo en algunas zonas del lugar. Quizás sería mejor añadir más altavoces en esas áreas.',
            departments: ['Música'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El espectáculo fue excelente, pero duró muy poco. Tal vez más shows a lo largo de la noche harían el evento más dinámico.',
            departments: ['Ambiente', 'Música'],
            categories: ['Peticiones', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El evento estuvo bien, pero creo que la señalización era confusa. No era fácil encontrar ciertas áreas del recinto.',
            departments: ['Servicios'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Los baños estaban muy llenos y no estaban tan limpios como deberían. Deberían revisarlos con más frecuencia.',
            departments: ['Servicios'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me encantó la música. El DJ mantuvo la energía alta toda la noche y la selección de canciones fue excelente.',
            departments: ['Música'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Lo mejor del evento fue el ambiente. La gente estaba muy animada y la decoración ayudaba a que todo se sintiera especial.',
            departments: ['Ambiente'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El espectáculo fue impresionante, especialmente las luces y los efectos. No esperaba que fuera tan interactivo.',
            departments: ['Ambiente', 'Música'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Definitivamente el personal. Fueron muy atentos y siempre estaban dispuestos a ayudar. Su amabilidad hizo una gran diferencia.',
            departments: ['Personal'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me gustó mucho la organización en general. Todo estuvo muy bien coordinado, desde la entrada hasta el servicio dentro del evento.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El ambiente y la decoración fueron lo mejor. Todo estuvo muy bien logrado y hacía que te sintieras inmerso en la fiesta.',
            departments: ['Ambiente'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Me encantó la comida y el servicio en la barra. Las bebidas llegaron rápido y los precios me parecieron razonables.',
            departments: ['Servicios', 'Pricing'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Lo mejor fue el ambiente que se creó. La gente estaba muy animada y la música ayudó mucho a mantener esa energía durante toda la noche.',
            departments: ['Ambiente', 'Música'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Para evitar las largas colas en la entrada, podrían implementar un sistema de tickets digitales con código QR que permita el acceso rápido.',
            departments: ['Seguridad', 'Personal'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Podrían instalar más dispensadores de agua en las zonas cercanas a la pista de baile, así la gente no tendría que ir hasta el bar para hidratarse.',
            departments: ['Servicios'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Sería genial si hubiera más personal en los baños para asegurarse de que estén limpios constantemente durante todo el evento.',
            departments: ['Servicios', 'Personal'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Para mejorar la experiencia, podrían agregar más áreas de descanso donde la gente pueda sentarse y relajarse sin salir del ambiente de la fiesta.',
            departments: ['Ambiente', 'Servicios'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Una buena solución para mejorar la experiencia sería ofrecer más variedad de comida, tal vez incluir opciones veganas o vegetarianas.',
            departments: ['Servicios', 'Pricing'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Podrían mejorar la iluminación en la pista de baile para que sea más envolvente y acorde al ritmo de la música.',
            departments: ['Música', 'Ambiente'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Sería útil tener más señalización clara dentro del evento, para que la gente pueda encontrar fácilmente los baños y las salidas de emergencia.',
            departments: ['Seguridad', 'Servicios'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Para mejorar el sonido, podrían ajustar los altavoces en diferentes zonas del recinto para que se escuche mejor en todas partes.',
            departments: ['Música', 'Ambiente'],
            categories: ['Soluciones'],
        },
    },
    {
        question: sampleQuestions[4],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La música estuvo bien, pero me sorprendió que no hubiera más variedad de géneros. Tal vez un cambio de ritmo hubiera estado interesante.',
            departments: ['Música'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El personal fue atento, pero quizás podrían haberse mostrado más dinámicos para que el ambiente fuera aún más energético.',
            departments: ['Personal'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La decoración fue buena, aunque podría haber más elementos temáticos que resalten la temática del evento.',
            departments: ['Ambiente'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[3],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Lo que más me gustó fue la mezcla entre el ambiente y la música, pero también la calidad del sonido fue impresionante.',
            departments: ['Música', 'Ambiente'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Todo estuvo bien, pero quizás podrían agregar más actividades interactivas para la gente, algo más que bailar y beber. También me gustaría saber como pudo entrar la gente al VIP, no encontré ningun sitio donde comprarlo.',
            departments: ['Ambiente', 'Servicios'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[4],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'La selección de música fue adecuada para el público, pero me hubiera gustado escuchar algo más de música en vivo.',
            departments: ['Música'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El espectáculo fue impresionante, pero me hubiera gustado más interacción con el público por parte de los artistas.',
            departments: ['Ambiente', 'Música'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El personal fue muy atento, pero algunos parecían un poco inexpertos en momentos de alta demanda en la barra. Me gustaría saber donde puedo dejar una propina para los que sí hicieron un buen trabajo.',
            departments: ['Personal', 'Servicios'],
            categories: ['Otros'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Vi una discusión entre un cliente y un miembro del personal en la entrada. El cliente estaba molesto por un malentendido con las entradas, pero el personal mantuvo la calma y manejó la situación de manera profesional.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Fortalezas'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'Hubo una pelea entre un cliente y el personal en la entrada. Creo que faltó un poco de control por parte de la seguridad para evitar que la situación escalara.',
            departments: ['Seguridad', 'Personal'],
            categories: ['Problemas', 'Peticiones'],
        },
    },
    {
        question: sampleQuestions[0],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El ambiente en general fue bueno, pero el mal rollo causado por la pelea entre un cliente y el personal afectó un poco al principio de la noche. Más control sobre este tipo de situaciones ayudaría.',
            departments: ['Ambiente', 'Seguridad'],
            categories: ['Peticiones', 'Problemas'],
        },
    },
    {
        question: sampleQuestions[1],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El personal estaba tratando de controlar a un cliente que parecía alterado. Aunque la situación fue difícil, creo que hicieron lo mejor que pudieron, pero habría sido ideal una respuesta más rápida de la seguridad.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Problemas', 'Fortalezas'],
        },
    },
    {
        question: sampleQuestions[2],
        answer: {
            type: 'FREE_TEXT' as const,
            value: 'El único problema que noté fue un enfrentamiento entre un cliente y el personal en la entrada. Tal vez con más capacitación para el personal sobre cómo manejar estos incidentes, se podría evitar que lleguen a ese punto.',
            departments: ['Personal', 'Seguridad'],
            categories: ['Soluciones'],
        },
    },
]

export const generateSampleMessages = (): TextFeedback[] => sampleMessages.map((m, idx) => ({ ...m, id: idx }))

export const generateLikertQuestions = (): LikertFeedback[] => {
    const questions = [
        generateQuestion('¿Qué tan amable te pareció el staff del evento?', 'SLIDER', ['Personal'], []),
        generateQuestion(
            'Del 1 al 5, ¿Cómo calificarías la relación calidad-precio de tu entrada?',
            'SLIDER',
            ['Pricing'],
            [],
        ),
        generateQuestion('¿Qué tan satisfecho estás con el ambiente del evento?', 'SLIDER', ['Ambiente'], []),
        generateQuestion(
            'Del 1 al 5  ¿Cómo calificarias la fluidez de entrada al club y la rapidez de los controles de acceso?',
            'SLIDER',
            ['Seguridad'],
            [],
        ),
        generateQuestion(
            '¿Del 1 al 5, ¿cómo calificarías la calidad del sonido en el evento? ',
            'SLIDER',
            ['Música'],
            [],
        ),
        generateQuestion(
            'Del 1 al 5, ¿qué tan amable y servicial te pareció el staff del evento?',
            'SLIDER',
            ['Personal'],
            [],
        ),
    ]

    return Array.from({ length: 200 + Math.floor(Math.random() * 11) }, () => {
        const randomQuestion = questions[Math.floor(Math.random() * 5) + 1]
        const answerValue = Math.floor(Math.random() * 5) + 1

        return {
            question: randomQuestion,
            answer: {
                categories: randomQuestion.categories,
                departments: randomQuestion.departments,
                type: 'SLIDER',
                value: answerValue,
            },
        }
    })
}

export const generateYesNoQuestions = (): YesNoFeedback[] => {
    const questions = [
        generateQuestion('¿Entraste rápido o tuviste que esperar mucho?', 'YES_NO', ['Seguridad'], []),
        generateQuestion('¿Te gustó la música?', 'YES_NO', ['Música'], []),
        generateQuestion(
            '¿Las luces y los efectos visuales mejoraron tu experiencia durante el show?',
            'YES_NO',
            ['Ambiente'],
            [],
        ),
        generateQuestion('¿Te resultó fácil encontrar las diferentes áreas del club?', 'YES_NO', ['Ambiente'], []),
        generateQuestion(
            '¿Te gustaría volver a asistir a un evento similar?',
            'YES_NO',
            ['Ambiente', 'Música', 'Personal', 'Seguridad', 'Servicios', 'Pricing'],
            [],
        ),
        generateQuestion('¿El set del DJ principal reflejaba lo que esperabas del evento?', 'YES_NO', ['Música'], []),
        generateQuestion(
            '¿Te gustaría que hubiera merchandising exclusivo del evento para recordar esta noche? ',
            'YES_NO',
            ['Servicios'],
            [],
        ),
        generateQuestion(
            '¿Recomendarías este evento a un amigo que también disfrute de la música electrónica? ',
            'YES_NO',
            ['Ambiente', 'Música', 'Personal', 'Seguridad', 'Servicios', 'Pricing'],
            [],
        ),
    ]

    return Array.from({ length: 200 + Math.floor(Math.random() * 11) }, () => {
        const randomNumber = Math.floor(Math.random() * 8)
        const randomQuestion = questions[randomNumber]
        const answerValue = Math.random() < 0.8

        return {
            question: randomQuestion,
            answer: {
                categories: randomQuestion.categories,
                departments: randomQuestion.departments,
                type: 'YES_NO',
                value: answerValue,
            },
        }
    })
}

export const demoSummaries = {
    Problemas: ` Los problemas principales fueron:
    - Desorden en la entrada, colas largas y confusión con las entradas. 
    - Espera en la barra, baños llenos y falta de limpieza. 
    - Algunos mencionaron una pelea entre un cliente y el personal, y que la seguridad no reaccionó lo suficientemente rápido.`,

    Peticiones: `Las peticiones más frecuentes fueron: 
    - Se solicitaron áreas de descanso
    - Mayor interacción con el público
    - Espectáculos más largos
    - Mejor sincronización de la música con los shows
    - mayor variedad musical. 
    - Pidieron más personal en la barra
    - mejor señalización
    - Más opciones de comida y bebidas
    - Mejor control del aforo en la pista de baile.`,

    Soluciones: `Las sugerencias más frecuentes fueron:
    - Los asistentes sugirieron implementar un sistema de tickets con QR para evitar colas
    - Más personal en los baños y barras
    - Señalización más clara
    - Más variedad de comida (incluyendo opciones veganas)
    - Ajustes en el sonido y la iluminación. 
    - Capacitar mejor al personal para manejar situaciones difíciles.`,

    Fortalezas: `Las fortalezas más destacadas fueron:
    - El ambiente y la decoración fueron muy apreciados
    - La amabilidad y profesionalismo del personal. 
    - El DJ y la selección musical mantuvieron la energía alta
    - los efectos visuales en los espectáculos fueron destacados como puntos positivos.`,

    Otros: `También hubieron comentarios sobre:
    - Falta de interacción entre artistas y público 
    - Necesidad de mayor dinamismo del personal
    - Hubo preguntas sobre cómo acceder al área VIP.`,
}
